import React from 'react'
import { Container } from 'react-bootstrap'
import './style.scss'
import divider from '../../img/divider.png'

export default props => {
    return (
        <>
            <header className="header" style={{
            }}>
                <Container fluid className="text-center">
                    {props.children}
                </Container>
            </header>
            <div style={{
                clipPath: 'polygon(0 0%, 50% 40.4%, 100% 0, 100% 100%, 0 100%)',
                backgroundColor: '#040404',
                marginTop: '-16%',
            }}>
                <img src={divider} alt={"divider"} width={'100%'} />
            </div>
        </>
    )
}
