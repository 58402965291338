export const topicProps = {
    bg: 'info',
    className: 'text-white'
}

export const switcherParams = {
    buttonProps: {
        variant: 'light',
        className: 'text-primary'
    }
}

export const summaryParams = {
    locationProps: {
    },
    dateProps: {
    },
    ticketButton: {
        text: 'Bestel tickets',
        props: {
            variant: 'primary',
            className: 'btn-shadow'
        }
    }
}

export const galleryParams = {
    colProps: {
        md: 4
    }
}

export const navbarParams = {
    togglerProps: {
        variant: "outline-light",
        size: "sm"
    },
    ticketButton: {
        text: 'Tickets',
        props: {
            variant: "outline-light",
            className: "text-light"
        }
    }
}

export const lineupParams = {
    highlightedColProps: {
        md: 6,
        className: "artist-highlighted act-col"
    },
    colProps: {
        md: 4,
        className: "act-col"
    },
    emptyText: 'To be announced',
    artist: {
        cardProps: {
            className: 'artist',
            style: {backgroundColor: '#d8d8d8'}
        },
        emptyText: 'To be announced'
    },
    stage: {
        buttonProps: {
            variant: 'primary'
        }
    }
}

export const sectionParams = {
    buttonProps: {
        variant: 'primary',
        className: 'btn-shadow'
    }
}

export const ticketParams = {
    colProps: {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3
    },
    button: {
        text: "Koop",
        props: {
            variant: null
        }
    }
}
